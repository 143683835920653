<template>
<div>
  <div class="vx-row" v-if="item">
    <div class="vx-col w-full lg:w-1/1">
      <!-- ABOUT CARD -->
      <vx-card :title="`Detail ${$i18n.t('Reminder')}`">
        <!-- ACTION SLOT -->
        <template slot="actions">
          <div class="flex">
            <vs-button color="primary" v-if="item.checked || !item.active" icon-pack="feather" icon="icon-refresh-cw" @click="$router.push({ name: 'reminder' })" class="mr-4">
              {{ $i18n.t('Repeat') }}
            </vs-button>

            <vs-button color="primary" v-if="!item.checked && item.active" icon-pack="feather" icon="icon-check-square" @click="checklist" class="mr-4">
              {{ $i18n.t('Finish') }}
            </vs-button>

            <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'reminder' })">
              {{ $i18n.t('Back') }}
            </vs-button>
          </div>
        </template>

        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/3 mb-2" v-for="(cell, i) in cells" :key="i">
            <div class="mt-5">
              <h6 class="font-bold">{{ cell.header }}</h6>
              <p>
                  <span v-if="cell.format">
                    {{ cell.format(item[cell.field]) }}
                  </span>
                  <span v-else-if="cell.formatCell">
                    {{ cell.formatCell(item) }}
                  </span>
                  <span v-else>
                    {{ item[cell.field] }}
                  </span>
              </p>
            </div>
          </div>
          <div class="vx-col w-full lg:w-1/3 mb-2">
            <div class="mt-5">
              <h6 class="mb-1">Status</h6>
              <vs-chip :color="chipColor" v-if="chipColor">
                {{value}}
              </vs-chip>
            </div>
          </div>
        </div>

      </vx-card>

      <!--  Popup Form  -->

    </div>
  </div>

  <vs-popup classContent="popup-example" :title="title" :active.sync="popupReminder">

    <div v-if="!isRepeat && !isChecklist">
      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <vs-input class="w-full mb-3" :label="$i18n.t('Title')" v-model="form.memo" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <label for="" class="vs-input--label">{{$i18n.t('Description')}}</label>
          <vs-textarea rows="6" class="w-full mb-3" v-model="form.deskripsi" />
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <small class="mb-1">Tujuan</small>
          <v-select multiple :label="$i18n.t('Purpose')" v-model="form.tujuan" :options="['sales.admin@argasolusi.com', 'finance@argasolusi.com', 'admin.support@argasolusi.com']" />
          <!--          <vs-input class="w-full mb-3" :label="$i18n.t('Purpose')" v-model="form.tujuan" />-->
        </div>
      </div>

      <vs-divider></vs-divider>
    </div>

    <div v-if="isChecklist">
      <!-- Row Checklist -->
      <div class="vx-row mb-3">
        <div class="vx-col md:w-1/1 w-full">
          <vs-checkbox v-model="reminderCheck">{{ $i18n.t('SetReminder') }}</vs-checkbox>
        </div>
      </div>

      <vs-divider></vs-divider>
    </div>

    <div v-if="(isChecklist ? reminderCheck : true)">
      <!-- Row Date -->
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{$i18n.t('Date')}}</label>
          <datepicker format="d/MM/yyyy" :value="form.tanggal" @input="form.tanggal = $moment($event)"></datepicker>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{$i18n.t('DateStartNotif')}}</label>
          <datepicker format="d/MM/yyyy" :value="form.tanggal_mulai_notif" @input="form.tanggal_mulai_notif = $moment($event)"></datepicker>
        </div>
      </div>
    </div>

    <div v-if="isChecklist ? reminderCheck : true">
      <vs-divider></vs-divider>

      <!-- Row Repeat Check -->
      <div class="vx-row mb-3">
        <div class="vx-col md:w-1/1 w-full">
          <vs-checkbox v-model="repeatCheck">{{ $i18n.t('Repeat') }}</vs-checkbox>
        </div>
      </div>

      <!-- Row Repeat Form -->
      <div class="vx-row" v-if="repeatCheck">
        <div class="vx-col md:w-1/3 w-full">
          <vs-input type="number" class="w-full" :label="$i18n.t('Years')" v-model="form.repeat_years" />
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <vs-input type="number" class="w-full" :label="$i18n.t('Months')" v-model="form.repeat_months" />
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <vs-input type="number" class="w-full" :label="$i18n.t('Days')" v-model="form.repeat_days" />
        </div>
        <div class="vx-col md:w-1/1 w-full" v-if="[form.repeat_years, form.repeat_months, form.repeat_days].some(item => item > 0)">
          <small>
            <i>
              * system will set reminder each
              <span v-if="form.repeat_years > 0">{{ form.repeat_years }} year<span v-if="form.repeat_years > 1">s </span> </span>
              <span v-if="form.repeat_months > 0">{{ form.repeat_months }} month<span v-if="form.repeat_months > 1">s </span> </span>
              <span v-if="form.repeat_days > 0">{{ form.repeat_days }} day<span v-if="form.repeat_days > 1">s </span> </span>
              automatically
            </i>
          </small>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-refresh-cw" @click="doRepeat" class="mt-5" v-if="isRepeat">Repeat</vs-button>
      <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-edit" @click="update" class="mt-5" v-else-if="isEdit">Update</vs-button>
      <vs-button color="primary" type="filled" icon-pack="feather" :icon="`icon-${reminderCheck ? 'refresh-cw' : 'check-circle'}`" @click="setReminder" class="mt-5" v-else-if="isChecklist">
        <span v-if="reminderCheck">{{$i18n.t('SetReminder')}}</span>
        <span v-else>{{$i18n.t('Finish')}}</span>
      </vs-button>
      <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="save" class="mt-5" v-else>Save</vs-button>
    </div>

  </vs-popup>

</div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from 'moment';

export default {
  name: "ReminderDetail",
  data () {
    return {
      title: `Form ${this.$i18n.t('Reminder')}`,
      isRepeat: false,
      isEdit: false,
      isChecklist: false,
      popupReminder: false,
      form: {
        tujuan: ['sales.admin@argasolusi.com', 'finance@argasolusi.com', 'admin.support@argasolusi.com'],
        repeat_years: 0,
        repeat_months: 0,
        repeat_days: 0,
      },
      repeatCheck: false,
      reminderCheck: false,
      repeats: [
        {
          value: 1,
          text: 'Every day',
        },
        {
          value: 2,
          text: 'Every week',
        },
        {
          value: 3,
          text: 'Every month',
        },
        {
          value: 4,
          text: 'Every year'
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      item: 'reminder/showItem',
      items: 'reminder/getItems'
    }),
    cells () {
      return [
        {
          header: this.$i18n.t('Title'),
          field: 'memo',
        },
        {
          header: this.$i18n.t('Description'),
          field: 'deskripsi',
        },
        {
          header: this.$i18n.t('Date'),
          field: 'tanggal',
          format: (field) => {
            return moment(new Date(field)).format('DD-MM-yyyy');
          }
        },
        {
          header: this.$i18n.t('NotifStart'),
          field: 'tanggal_mulai_notif',
          format: (field) => {
            return moment(new Date(field)).format('DD-MM-yyyy');
          }
        },
      ]
    },
    chipColor () {
      let data = this.item;

      if (data.checked)
        return 'primary';
      else if (!data.active)
        return 'danger';
      else
        return 'success';
    },
    value () {
      let data = this.item;

      if (data.checked)
        return 'checked';
      else if (!data.active)
        return 'expired';
      else
        return 'active';
    }
  },
  methods: {
    update () {
      let body = {
        date: this.selectDate.date,
      };

      this.$store.dispatch('reminder/checkItem', { id: this.id, body }).then((response) => {
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          this.activePrompt = false;
          this.confirmCode = "";
          this.confirm = "";
          this.select = false;
          this.selectDate = false;
          this.load();
        });
      });
    },
    edit (tr) {
      this.select = this.item;
      this.selectDate = tr;
      this.activePrompt = true;
      this.generateCode();
    },
    generateCode () {
      this.confirmCode = `#${Math.abs(Math.floor(Math.random() * 999)) + 2000}`;
    },
    load () {
      this.$store.dispatch('reminder/fetchItems');
      this.$store.dispatch('reminder/detailItem', { id: this.id  });
    },

    checklist () {
      this.reset();
      this.title = this.$i18n.t('Finish') + ' ' + this.$i18n.t('Reminder');
      this.$store.dispatch('reminder/detailItem', { id: this.item.id }).then(resp => {
        this.form = resp.data.values;
        let hasRepeat = [this.form.repeat_days, this.form.repeat_months, this.form.repeat_years].some(item => item > 0);
        if (hasRepeat) {
          this.repeat = true;
        }
        this.form.tujuan = this.form.tujuan.split('|');
        this.isChecklist = true;
        this.popupReminder = true;
      });
    },
    dispatch (payload, address) {
      this.$store.dispatch(address, payload).then((response) => {
        this.popupReminder = false;
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          this.loadReminders();
          this.loadReminderHistories();
        });
      }).catch(err => {
        console.log(err);
        if (!err.response) {
          // network error
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }
        this.$vs.notify({
          title:'Opps something error',
          text: err.response.data.message,
          color:'danger'})
      });
    },
    reset () {
      this.isRepeat = false;
      this.isEdit = false;
      this.isChecklist = false;
      this.popupReminder = false;
      this.form = {
        tujuan: ['sales.admin@argasolusi.com', 'finance@argasolusi.com', 'admin.support@argasolusi.com'],
        repeat_years: 0,
        repeat_months: 0,
        repeat_days: 0,
      };
      this.repeatCheck = false;
      this.reminderCheck = false;
    },
    setReminder () {
      let payload = {
        body: this.form
      }, address = "reminder/updateItem";

      // set ID
      payload.id = this.form.id;

      // set a put method
      payload.body._method = 'put';

      // set unchecked status
      payload.body.checked = this.reminderCheck ? "0" : "1";

      // put set reminder
      payload.body.checklist = true;

      this.dispatch(payload, address);
    },
    save () {
      let payload = {
        body: this.form
      }, address = "reminder/storeItem";

      this.dispatch(payload, address);
    },
  },
  created () {
    this.id = this.$secure.decrypt(this.$route.params.id);
    this.load();
  }
}
</script>

<style lang="scss" scpoped>
.vs-chip-primary {
  background: rgba(var(--vs-primary),.15);
  color: rgba(var(--vs-primary),1) !important;
  font-weight: 500;
}
.vs-chip-success {
  background: rgba(var(--vs-success),.15);
  color: rgba(var(--vs-success),1) !important;
  font-weight: 500;
}
.vs-chip-warning {
  background: rgba(var(--vs-warning),.15);
  color: rgba(var(--vs-warning),1) !important;
  font-weight: 500;
}
.vs-chip-danger {
  background: rgba(var(--vs-danger), .15);
  color: rgba(var(--vs-danger), 1) !important;
  font-weight: 500;
}
</style>
